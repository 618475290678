import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type botSliceState = {
  CSSInjected: boolean
}

const slice = createSlice({
  name: 'bot',
  initialState: {
    CSSInjected: false
  } as botSliceState,
  reducers: {
    setCSSInjected: (
      state,
      { payload: { CSSInjected } }: PayloadAction<{ CSSInjected: boolean }>
    ): void => {
      state.CSSInjected = CSSInjected
    }
  }
})

export const { setCSSInjected } = slice.actions

export default slice.reducer

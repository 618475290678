import {
  RetorikAgent,
  characters,
  Configuration,
  ViewsConfiguration,
  ChatbotData,
  PonyfillFactoryCredentials,
  AddressData,
  UserData,
  AvailableViews,
  AvailableSubViews,
  sendEvent,
  RetorikMainComponentConfiguration,
  RetorikMenusConfiguration,
  RetorikMainComponentProps
} from '@davi-ai/retorik-framework'

import React, { useEffect, useState } from 'react'

import Location from '../styles/icons/location'
import { MapIcon } from '../styles/icons/map'
import { botEventHandler } from './CustomBotEventHandler'
import { useSelector } from 'react-redux'
import { RootState } from '../stores/globalStore'
import { LatLngExpression, LatLngLiteral } from 'leaflet'
import { buildCustomMenu } from '../utils/customJOMenu'
import {
  USER_LOCATION_KEY,
  getLocalStorage,
  saveLocalStorage
} from '../utils/globalUtils'

import { FullMenu } from '@davi-ai/retorik-framework-hospitality'
import { Map, MapSettings } from '@davi-ai/retorik-map'
import { POIType } from '../models/POI'

interface BotProps {
  tenant: string
}

const hospitalityFullMenu = new FullMenu(sendEvent).menu

const Bot = ({ tenant }: BotProps): JSX.Element => {
  const [injectConfig, setInjectConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)

  const userLocation: LatLngExpression | undefined = useSelector(
    (state: RootState) => state.map.currentUserLocation
  )
  const tenantList = useSelector((state: RootState) => state.tenant.tenantList)

  const [_externalComponents, setExternalComponent] = useState<any>([
    { name: 'mapDisplay', component: <Map tenantList={tenantList} /> }
  ])

  const [loaded, setLoaded] = useState<boolean>(false)

  const [defaultMenuConfig, setDefaultMenuConfig] = useState<
    RetorikMenusConfiguration | undefined
  >({
    baseMenu: [
      { view: AvailableViews.home, indice: 1 },
      {
        clickHandler: (): void => console.log('Test'),
        customDisplay: 'mapDisplay',
        label: { 'fr-FR': 'Carte Interactive', 'en-US': 'Interactive map' },
        icon: <MapIcon />,
        indice: 2
      },
      { view: AvailableViews.news, indice: 3 },
      { view: AvailableViews.weather, indice: 4 },
      { view: AvailableViews.emergency, indice: 5 }
    ],
    customMenu: [hospitalityFullMenu],
    subMenu: [
      { view: AvailableSubViews.history, indice: 1 },
      { view: AvailableSubViews.tutorial, indice: 2 },
      {
        clickHandler: (): void => {
          const EVENT_REDIRECT_HOME = new CustomEvent('CustomEvent', {
            detail: {
              name: 'RouterHome',
              value: '/'
            }
          })
          document.dispatchEvent(EVENT_REDIRECT_HOME)
        },
        customDisplay: undefined,
        label: { 'fr-FR': 'Changer de lieu', 'en-US': 'Change location' },
        icon: <Location />,
        indice: 3
      }
    ]
  })

  const [defaultConfig, setDefaultConfig] = useState<Configuration | undefined>(
    {
      ...defaultMenuConfig,
      isUsedOnBorne: false,
      answerpanel: true,
      subtitles: false,
      locales: {
        getDefaultFromBrowser: false
      },
      logo: {
        src: ''
      },
      enableTelemetry: true,
      speechRecognitionOptions: {
        enableActivationSound: true
      }
    }
  )

  const [defaultViewsConfig, setDefaultViewsConfig] =
    useState<ViewsConfiguration>({
      homeRoute: 'home',
      views: {
        home: {
          background: {
            image: 'images/fond_borneHorizontale.webp',
            style: 'image'
          }
        }
      }
    })

  const chatbotData: ChatbotData = {
    size: '200vh',
    height: undefined
  }

  const userData: UserData = {
    name: '',
    id: '',
    username: '',
    nom: '',
    prenom: '',
    email: '',
    token: '',
    referrer: '',
    ipaddress: ''
  }

  const ponyfillFactoryCredentials: PonyfillFactoryCredentials = {
    region: 'francecentral',
    subscriptionKey: '2f62867e22a544b78a16c99a2707ef49'
  }

  useEffect(() => {
    return (): void => {
      setExternalComponent([])
      setDefaultMenuConfig(undefined)
      setDefaultConfig(undefined)
      console.log('DESTROY BOT')
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let copyConfig: Configuration = { ...defaultConfig }
    const copyConfigViews: ViewsConfiguration = { ...defaultViewsConfig }
    let externalComponents = [
      { name: 'mapDisplay', component: <Map tenantList={tenantList} /> }
    ]
    let mapSettings: MapSettings = {}

    if (tenant === 'DemoDaviParis') {
      mapSettings = {
        showFilter: [
          POIType.afaire,
          POIType.avoir,
          POIType.hebergement,
          POIType.service
        ]
      }
    }

    if (userLocation) {
      saveLocalStorage(USER_LOCATION_KEY, userLocation)
      const positionUpdated = {
        position: {
          latitude: (userLocation as LatLngLiteral).lat,
          longitude: (userLocation as LatLngLiteral).lng
        }
      }
      copyConfig.position = positionUpdated.position

      externalComponents = [
        {
          name: 'mapDisplay',
          component: (
            <Map
              userLocation={userLocation}
              settings={mapSettings}
              tenantList={tenantList}
              activeTenant={tenant}
            />
          )
        }
      ]
    } else if (getLocalStorage(USER_LOCATION_KEY) !== undefined) {
      const storedLocation = getLocalStorage(USER_LOCATION_KEY)

      externalComponents = [
        {
          name: 'mapDisplay',
          component: (
            <Map
              userLocation={storedLocation}
              settings={mapSettings}
              tenantList={tenantList}
              activeTenant={tenant}
            />
          )
        }
      ]
    }
    setExternalComponent(externalComponents)

    let background = defaultViewsConfig.views.home.background.image
    let logo = defaultConfig && defaultConfig.logo?.src

    // Handle CustomMenu
    const customMenu: any[] = []
    buildCustomMenu(customMenu, sendEvent)

    if (tenant === 'DemoDaviParis') {
      copyConfig.customMenu = customMenu
    } else {
      copyConfig.customMenu = [hospitalityFullMenu]
    }

    switch (tenant) {
      case 'asklea_lesbertranges':
        background =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/bertranges/background/fond-ecran-bertranges-1.jpg'
        logo =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/bertranges/logo/logo-ot-lacharite.jpg'
        break
      case 'asklea_morvansommetsgl':
        background =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/sommetsgrandslacs/background/fond-ecran-sommets-grands-lacs-1.jpg'
        logo =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/sommetsgrandslacs/logo/logo-sommets-grands-lacs.png'
        break
      case 'asklea_rivesdumorvan':
        background =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/rivesdumorvan/background/fond-ecran-rives-du-morvan-1.jpg'
        break
      case 'asklea_stpierremc':
        background =
          'https://cdn.retorik.ai/retorik-framework/imagefiles/PITACA/saintpierremagnycours/background/fond-ecran-saint-pierre-magny-cours-3.jpg'
        break
      case 'DemoDaviParis':
        background =
          'https://studio.retorik.ai/DemoDaviParis/media/backgroundJO.jpg'
        logo = 'https://studio.retorik.ai/DemoDaviParis/media/RETORIKdavi.png'
        if (copyConfig && copyConfig.subMenu)
          copyConfig.subMenu = copyConfig.subMenu.slice(0, -1)
        break
      default:
        background = 'images/fond_borneHorizontale.webp'
        break
    }

    if (background !== defaultViewsConfig.views.home.background.image) {
      copyConfigViews.views.home.background.image = background
    }
    if (defaultConfig && logo && logo !== defaultConfig.logo?.src) {
      copyConfig.logo = {
        src: logo
      }
    }

    const addressData: AddressData = {
      tenant: tenant
    }

    // Hack for preview
    if (tenant === 'asklea_lesbertranges') {
      addressData.prefix = 'dev'
    }

    setDefaultViewsConfig(copyConfigViews)
    setDefaultConfig(copyConfig)
    const newConfig: RetorikMainComponentProps =
      tenant === 'asklea_lesbertranges'
        ? {
            config: undefined,
            menuConfig: defaultMenuConfig,
            addressData,
            userData,
            agentData: characters.zahra
          }
        : {
            config: copyConfig,
            viewsConfig: copyConfigViews,
            ponyfillFactoryCredentials,
            chatbotData,
            addressData,
            userData,
            agentData: characters.zahra
          }

    setInjectConfig(newConfig)
    setLoaded(true)
  }, [tenant, userLocation])

  return loaded && injectConfig ? (
    <RetorikAgent
      {...injectConfig}
      externalEventHandler={botEventHandler}
      externalComponents={_externalComponents}
    />
  ) : (
    <React.Fragment />
  )
}

export { Bot }

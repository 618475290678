import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { MapState } from '../models/Map'
import { LatLngExpression } from 'leaflet'

type mapState = {
  loading: boolean
  selectedPOIIdentifier: string
  mapState: MapState | undefined
  currentUserLocation: LatLngExpression | undefined
}

const slice = createSlice({
  name: 'map',
  initialState: {
    loading: false,
    selectedPOIIdentifier: '',
    mapState: undefined,
    currentUserLocation: undefined
  } as mapState,
  reducers: {
    toggleLoader: (
      state,
      { payload: { loading } }: PayloadAction<{ loading: boolean }>
    ): void => {
      state.loading = loading
    },
    selectPOIIdentifier: (
      state,
      {
        payload: { selectedPOIIdentifier }
      }: PayloadAction<{ selectedPOIIdentifier: string }>
    ): void => {
      state.selectedPOIIdentifier = selectedPOIIdentifier
    },
    updateMapState: (
      state,
      { payload: { mapState } }: PayloadAction<{ mapState: MapState }>
    ): void => {
      state.mapState = mapState
    },
    updateUserLocation: (
      state,
      {
        payload: { currentUserLocation }
      }: PayloadAction<{ currentUserLocation: LatLngExpression | undefined }>
    ): void => {
      state.currentUserLocation = currentUserLocation
    }
  }
})

export const {
  toggleLoader,
  selectPOIIdentifier,
  updateMapState,
  updateUserLocation
} = slice.actions

export default slice.reducer

import type { LatLngExpression } from 'leaflet'

type POI = {
  name: string
  type: string
  Location?: LatLngExpression
  contentItemId: string
  description?: string
  imageURL?: string
  address?: string
  phone?: string
}

type Place = {
  latitude: number
  longitude: number
  telephone: string
  streetAddress: string
  postalCode: string
  addressLocality: string
}

enum POIType {
  restaurant = 'restaurant',
  afaire = 'a-faire',
  avoir = 'a-voir',
  hebergement = 'hebergement',
  produit = 'produit',
  service = 'service'
}

type RestaurantPOI = {
  displayText: string
  render: string
  starRating: number | null
  place: Place
  servesCuisine: string | null
  owner: string
  thing: {
    description: string
    categories: string[] | null
    name: string
    alternateName: string | null
    image: {
      urls: string[]
      paths: string[]
    }
    url: {
      text: string
      url: string
    }
  }
  contentItemId: string
}

type LodgingBusinessPOI = {
  checkinTime: string
  checkoutTime: string
  render: string
  displayText: string
  numberOfRooms: number
  petsAllowed: boolean | null
  starRating: string
  place: Place
  thing: {
    name: string
    description: string
    categories: string[] | null
    alternateName: string | null
    url: {
      text: string
      url: string
    }
    image: {
      urls: string[]
      paths: string[]
    }
  }
  contentItemId: string
}

interface ServicePOI {
  displayText: string
  contentItemId: string
  serviceType: any
  place: Place
  thing: {
    name: string
    image: {
      urls: string[]
      paths: string[]
    }
    url: {
      text: string
      url: string
    }
    categories: any
    description: string
    alternateName: any
  }
}

interface TouristAttractionPOI {
  displayText: string
  contentItemId: string
  touristType: string
  place: Place
  thing: {
    alternateName: string | null
    categories: string[] | null
    description: string
    image: {
      paths: string[]
      urls: string[]
    }
    url: {
      text: string
      url: string
    }
    name: string
  }
}

interface TouristDestinationPOI {
  displayText: string
  contentItemId: string
  touristType: null
  place: Place
  thing: {
    alternateName: null
    categories: null
    description: string
    image: {
      paths: string[]
      urls: string[]
    }
    url: {
      text: string
      url: string
    }
    name: string
  }
}

interface ProduitPOI {
  displayText: string
  contentItemId: string
  place: Place
  thing: {
    alternateName: null
    categories: null
    description: string
    image: {
      paths: string[]
      urls: string[]
    }
    url: {
      text: string
      url: string
    }
    name: string
  }
}

export type {
  POI,
  Place,
  RestaurantPOI,
  LodgingBusinessPOI,
  ServicePOI,
  TouristAttractionPOI,
  TouristDestinationPOI,
  ProduitPOI
}
export { POIType }

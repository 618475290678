import type { Tenant } from '../models/Tenant'

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { RetorikTenantInfo } from '../models/RetorikTenantInfo'
import { tenants } from '../utils/tenantList'

const URL_API_TENANT = 'https://dev.management.retorik.ai/api/getAgents'
const URL_TOKEN_API = 'https://auth.retorik.ai/oidc/token'
const API_CLIENT_ID = 'asklea'
const API_CLIENT_SERCRET = '388D45FA-B36B-4988-BA59-B187D329C207'

const getToken = async (): Promise<any> => {
  const token = (
    await fetch(URL_TOKEN_API, {
      method: 'POST',
      body: `grant_type=client_credentials&scope=profile&client_id=${API_CLIENT_ID}&client_secret=${API_CLIENT_SERCRET}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  ).json()
  return token
}

export const tenantApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: URL_API_TENANT,
    prepareHeaders: async (headers) => {
      const token = await getToken()
      console.log(token)
      // If we have a token set in state, let's assume that we should be passing it.
      if (token && token.access_token) {
        headers.set('authorization', `Bearer ${token.access_token}`)
      }

      return headers
    }
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    getTenant: builder.query<{ [key: string]: Tenant }, void>({
      query: () => ``,
      transformResponse: (
        response: RetorikTenantInfo[]
      ): { [key: string]: Tenant } => {
        const res: { [key: string]: Tenant } = {}
        if (response && response.length > 0) {
          const filteredData = response.filter(
            (a: RetorikTenantInfo) =>
              a.showOnAskLea != null && a.showOnAskLea === true
          )
          filteredData.forEach((agent: any) => {
            res[agent.name] = {
              name: agent.name,
              retorikName: agent.name,
              areaLocation: agent.agentZone?.zone
            }
          })
        }
        return res
      },
      // Temporary return mock tenants from error
      transformErrorResponse: () => tenants
    })
  }),
  reducerPath: 'tenantApi'
})

// Export hooks for usage in functional components
export const { useGetTenantQuery } = tenantApi

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import { tenantApi } from '../services/tenantService'
import tenantReducer from '../features/tenantSlice'
import botReducer from '../features/botSlice'
import mapReducer from '../features/mapSlice'
import type { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore'

const rootReducer = combineReducers({
  [tenantApi.reducerPath]: tenantApi.reducer,
  tenant: tenantReducer,
  map: mapReducer,
  bot: botReducer
})

export const setupStore = (
  preloadedState?: PreloadedState<RootState>
): ToolkitStore => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({
        serializableCheck: false
      }).concat(tenantApi.middleware),
    preloadedState
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

// import { useIonRouter } from '@ionic/react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCSSInjected } from '../features/botSlice'
import {
  selectPOIIdentifier,
  toggleLoader,
  updateMapState,
  updateUserLocation
} from '../features/mapSlice'
// import { setDisplayPOIFinished } from '../features/poiSlice'
import type { RootState } from '../stores/globalStore'
import { injectShadowCSS } from '../utils/botUtils'
import { setActiveTenant } from '../features/tenantSlice'
import { USER_LOCATION_KEY } from '../utils/globalUtils'

const BotCustomEventListenner = (): any => {
  const dispatch = useDispatch()
  // const router = useIonRouter()
  const botCSSInjected: boolean = useSelector(
    (state: RootState) => state.bot.CSSInjected
  )
  useEffect(() => {
    const handleEventListerner = async (e: any): Promise<void> => {
      // const host = document.querySelector('#retorik-shadow')
      const customEvent: CustomEvent = e as CustomEvent
      if (customEvent.detail) {
        console.log(customEvent.detail)
        if (customEvent.detail?.name === 'ToggleLoader') {
          dispatch(toggleLoader({ loading: false }))
        }
        if (customEvent.detail?.name === 'SelectPoiFromBot') {
          // Select POI on MAP
          dispatch(
            selectPOIIdentifier({
              selectedPOIIdentifier: customEvent.detail?.value
            })
          )
        }
        if (customEvent.detail?.name === 'ShowPOIFinished') {
          // Select POI on MAP
          /* dispatch(
            setDisplayPOIFinished({
              displayPOIFinished: true
            })
          )
          dispatch(
            toggleLoader({
              loading: false
            })
          ) */
        }
        if (customEvent.detail?.name === 'RouterHome') {
          dispatch(updateUserLocation({ currentUserLocation: undefined }))
          dispatch(setCSSInjected({ CSSInjected: false }))
          dispatch(setActiveTenant({ activeTenant: '' }))
          dispatch(
            updateMapState({
              mapState: { location: undefined, zoom: undefined }
            })
          )
          localStorage.removeItem(USER_LOCATION_KEY)
          window.location.replace(customEvent.detail.value ?? '/')
          // router.push(customEvent.detail.value ?? '/')
        }

        if (customEvent.detail?.name === 'InjectCSS') {
          if (!botCSSInjected) {
            injectShadowCSS()
            dispatch(setCSSInjected({ CSSInjected: true }))
          }
        }
      }
    }

    document.addEventListener('CustomEvent', handleEventListerner)
    return (): void => {
      document.removeEventListener('CustomEvent', handleEventListerner)
    }
  })
  return null
}

export { BotCustomEventListenner }

/* eslint-disable react/self-closing-comp */
import React from 'react'

const MapIcon = (): JSX.Element => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      enableBackground='new 0 0 64 64'
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        height: '100%'
      }}
      xmlSpace='preserve'>
      <polyline
        fill='none'
        stroke='#a4a7af'
        strokeWidth='2'
        strokeLinejoin='bevel'
        strokeMiterlimit='10'
        points='54.083,51 
	63.083,42 54.083,33 '
      />
      <line
        fill='none'
        stroke='#a4a7af'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='63'
        y1='42'
        x2='9'
        y2='42'
      />
      <polyline
        fill='none'
        stroke='#a4a7af'
        strokeWidth='2'
        strokeLinejoin='bevel'
        strokeMiterlimit='10'
        points='10.083,13 
	1.083,22 10.083,31 '
      />
      <line
        fill='none'
        stroke='#a4a7af'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='1'
        y1='22'
        x2='55'
        y2='22'
      />
    </svg>
  )
}

export default MapIcon

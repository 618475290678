import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import React from 'react'
import { IonReactRouter } from '@ionic/react-router'
// import { Geolocation } from '@capacitor/geolocation';
import { Redirect, Route } from 'react-router-dom'
import GetLocation from './pages/geolocation'
import Home from './pages/home'
// import aVoir from './pages/aVoir';
// import { useTenant } from "./components/Context/TenantContext"
// import { TenantProvider } from './components/contexts/TenantContext'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.scss'
import './theme/global.scss'

setupIonicReact()

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet placeholder={undefined}>
          <Route path='/' exact>
            <Redirect to='/get_location' />
          </Route>
          <Route path='/get_location' exact>
            <GetLocation />
          </Route>
          <Route path='/lesbertranges' exact>
            <Home tenantName='asklea_lesbertranges' />
          </Route>
          <Route path='/morvansommetsgl' exact>
            <Home tenantName='asklea_morvansommetsgl' />
          </Route>
          <Route path='/rivesdumorvan' exact>
            <Home tenantName='asklea_rivesdumorvan' />
          </Route>
          <Route path='/stpierremc' exact>
            <Home tenantName='asklea_stpierremc' />
          </Route>
          <Route path='/DemoDaviParis' exact>
            <Home tenantName='DemoDaviParis' />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App

const botEventHandler: any = (action: any): boolean => {
  if (!action) {
    return false
  }

  const activity = action.payload?.activity
  if (activity) {
    switch (action.type) {
      case 'DIRECT_LINE/POST_ACTIVITY_FULFILLED':
        if (activity.value?.name === 'hospitality.showPoiById') {
          // Start loader
          const EVENT_START_LOADER = new CustomEvent('CustomEvent', {
            detail: {
              name: 'ToggleLoader'
            }
          })
          document.dispatchEvent(EVENT_START_LOADER)

          // Handle activity show detail finished
          const EVENT_SHOW_POI_FINISHED = new CustomEvent('CustomEvent', {
            detail: {
              name: 'ShowPOIFinished'
            }
          })
          console.log('Send EVENT', EVENT_SHOW_POI_FINISHED)
          document.dispatchEvent(EVENT_SHOW_POI_FINISHED)
        } else if (activity.type === 'event' && activity.name === 'setUser') {
          // Bot Started => Inject CSS to Shadow
          const EVENT_INJECT_CSS = new CustomEvent('CustomEvent', {
            detail: {
              name: 'InjectCSS'
            }
          })
          document.dispatchEvent(EVENT_INJECT_CSS)
        }
        break
      case 'DIRECT_LINE/INCOMING_ACTIVITY':
        if (
          activity.type === 'event' &&
          activity.name === 'hospitality.ShowPointOfInterest'
        ) {
          const EVENT_SELECT_POI_FROMBOT = new CustomEvent('CustomEvent', {
            detail: {
              name: 'SelectPoiFromBot',
              value: activity.value?.identifier
            }
          })
          console.log('Send EVENT', EVENT_SELECT_POI_FROMBOT)
          document.dispatchEvent(EVENT_SELECT_POI_FROMBOT)
        }
        break
      default:
        break
    }
  }

  return false
}

export { botEventHandler }

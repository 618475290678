import {
  WhatToDoIcon,
  WhatToSeeIcon,
  WhereToEatIcon,
  WhereToSleepIcon,
  ServicesIcon,
  AgendaIcon,
  MenuItem
} from '@davi-ai/retorik-framework-hospitality'

const buildCustomMenu = (customMenu: any[], handleClick: Function): any[] => {
  customMenu.push(
    new WhatToDoItem(
      1,
      handleClick,
      undefined,
      translation.whatToDo
    ).getAsCustomMenu()
  )
  customMenu.push(
    new WhereToEatItem(
      2,
      handleClick,
      undefined,
      translation.whereToEat
    ).getAsCustomMenu()
  )
  customMenu.push(
    new WhereToSleepItem(
      3,
      handleClick,
      undefined,
      translation.whereToSleep
    ).getAsCustomMenu()
  )
  customMenu.push(
    new WhatToSeeItem(
      4,
      handleClick,
      undefined,
      translation.whatToSee
    ).getAsCustomMenu()
  )

  // customMenu.push(new LocalProductsItem(4, handleClick, undefined, translation.localProducts).getAsCustomMenu())

  customMenu.push(
    new ServicesItem(
      5,
      handleClick,
      undefined,
      translation.services
    ).getAsCustomMenu()
  )
  customMenu.push(
    new AgendaItem(
      0,
      handleClick,
      undefined,
      translation.agenda
    ).getAsCustomMenu()
  )
  return customMenu
}
export { buildCustomMenu }

class AgendaItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'agenda')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllEvents')
    }

    this.icon = AgendaIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

/* class LocalProductsItem extends MenuItem {
  constructor(indice, sendActivity, color, label) {
    super(indice, label, undefined, undefined, true, 'localProducts')

    this.clickHandler = (): void => {
      const activity = {
        type: 'event',
        name: 'Hospitality.ShowAllProducts'
      }
      sendActivity(activity)
    }

    this.icon = LocalProductsIcon({ className: 'rf-h-full rf-w-full', color })
  }
}
*/
class ServicesItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'services')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllServices')
    }

    this.icon = ServicesIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

class WhatToDoItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'whatToDo')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllTouristAttractions')
    }

    this.icon = WhatToDoIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

class WhatToSeeItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'whatToSee')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllTouristDestinations')
    }

    this.icon = WhatToSeeIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

class WhereToEatItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'whereToEat')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllFoodEstablishments')
    }
    this.icon = WhereToEatIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

class WhereToSleepItem extends MenuItem {
  constructor(indice, sendEvent, color, label) {
    super(indice, label, undefined, undefined, true, 'whereToSleep')

    this.clickHandler = (): void => {
      sendEvent('Hospitality.ShowAllLodgingBusinesses')
    }

    this.icon = WhereToSleepIcon({ className: 'rf-h-full rf-w-full', color })
  }
}

const translation = {
  whatToDo: {
    'fr-FR': 'à faire pendant les JO',
    'en-US': 'to do during the Olympics',
    'en-GB': 'what to do',
    'es-ES': 'what to do',
    'de-DE': 'was zu machen'
  },
  whatToSee: {
    'fr-FR': 'Les sites à ne pas manquer',
    'en-US': 'must-see sites',
    'en-GB': 'what to see',
    'es-ES': 'what to see',
    'de-DE': 'was zu sehen'
  },
  whereToSleep: {
    'fr-FR': 'où dormir',
    'en-US': 'where to sleep',
    'en-GB': 'where to sleep',
    'es-ES': 'where to sleep',
    'de-DE': 'wo zu schlafen'
  },
  whereToEat: {
    'fr-FR': 'où manger',
    'en-US': 'where to eat',
    'en-GB': 'where to eat',
    'es-ES': 'where to eat',
    'de-DE': 'wo zu essen'
  },
  localProducts: {
    'fr-FR': 'produits locaux',
    'en-US': 'local products',
    'en-GB': 'local products',
    'es-ES': 'local products',
    'de-DE': 'lokale Produkte'
  },
  services: {
    'fr-FR': 'services',
    'en-US': 'services',
    'en-GB': 'services',
    'es-ES': 'services',
    'de-DE': 'Service'
  },
  agenda: {
    'fr-FR': 'Le calendrier des compétitions',
    'en-US': 'The competitions schedule',
    'en-GB': 'schedule',
    'es-ES': 'schedule',
    'de-DE': 'Kalender'
  }
}

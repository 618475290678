import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Tenant } from '../models/Tenant'

type tenantState = {
  activeTenant: string
  tenantList: { [key: string]: Tenant }
}

const slice = createSlice({
  name: 'tenant',
  initialState: {
    activeTenant: '',
    tenantList: {}
  } as tenantState,
  reducers: {
    setActiveTenant: (
      state,
      { payload: { activeTenant } }: PayloadAction<{ activeTenant: string }>
    ): void => {
      state.activeTenant = activeTenant
    },
    setTenantList: (
      state,
      {
        payload: { tenantList }
      }: PayloadAction<{ tenantList: { [key: string]: Tenant } }>
    ): void => {
      state.tenantList = tenantList
    }
  }
})

export const { setActiveTenant, setTenantList } = slice.actions

export default slice.reducer

import L, { LatLngExpression, LatLngLiteral } from 'leaflet'

function strParse(str: string, ...args: any[]): string {
  let i = 0

  return str.replace(/%s/g, () => args[i++])
}

const RETORIK_URL = 'https://studio.retorik.ai'
const USER_LOCATION_KEY = 'userLocation'
function isMarkerInsidePolygon(
  cityLocation: LatLngLiteral,
  tenantPolygon: Array<LatLngExpression>
): boolean {
  const polyPoints = L.polygon(tenantPolygon)
  let inside = false

  inside = polyPoints.getBounds().contains(cityLocation)

  return inside
}

const saveLocalStorage = (key: string, value: any): void => {
  const valueParsed = JSON.stringify(value)
  if (valueParsed) localStorage.setItem(key, valueParsed)
}

const getLocalStorage = (key: string): any | undefined => {
  const value = localStorage.getItem(key)
  if (value && typeof value === 'string') {
    return JSON.parse(value)
  }
  return null
}

export {
  strParse,
  isMarkerInsidePolygon,
  RETORIK_URL,
  USER_LOCATION_KEY,
  saveLocalStorage,
  getLocalStorage
}

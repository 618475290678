const CloseBotButtonClick = (): void => {
  const retorikShadow = document.querySelector('#retorik-shadow')
  if (
    retorikShadow &&
    retorikShadow.shadowRoot &&
    retorikShadow.shadowRoot.querySelector("[data-map='fermer']")
  ) {
    const closeButton: HTMLElement | null =
      retorikShadow.shadowRoot.querySelector("[data-map='fermer']")
    if (closeButton) {
      closeButton.click()
    }
  } else if (
    retorikShadow &&
    retorikShadow.shadowRoot &&
    retorikShadow.shadowRoot.querySelector("[data-map='dashboard']")
  ) {
    const closeButton: HTMLElement | null =
      retorikShadow.shadowRoot.querySelector("[data-map='dashboard']")
    if (closeButton) {
      closeButton.click()
    }
  }
}

const injectShadowCSS = (): void => {
  const host = document.querySelector('#retorik-shadow')
  if (host && host.shadowRoot) {
    // Inject shadow dom custom CSS
    const sheets = Array.from(document.styleSheets)
    const styleToInject = sheets.filter((s: CSSStyleSheet) => {
      return Array.from(s.cssRules).some(
        (c: CSSRule) => c.cssText.indexOf('#retorik-shadow') > -1
      )
    })
    if (styleToInject && styleToInject.length > 0) {
      styleToInject.forEach((s) => {
        const host = document.querySelector('#retorik-shadow')
        const rules = Array.from(s.cssRules).filter(
          (c: CSSRule) =>
            c.cssText.indexOf('#retorik-shadow') > -1 ||
            c.cssText.indexOf('@keyframes') > -1
        )
        const strCss: string = rules.map((r) => r.cssText).join(' ')
        const style = document.createElement('style')
        style.innerHTML = strCss.replaceAll('#retorik-shadow', ':host')
        if (host && host.shadowRoot) host.shadowRoot.appendChild(style)
      })
    }
  }
}

export { CloseBotButtonClick, injectShadowCSS }
